import React from 'react'
import DrillingBanner from '../Components/DrillingBanner/DrillingBanner'
import DrillingDetails from '../Components/DrillingDetails/DrillingDetails'
import Footer from '../Components/Footer/Footer'

function Drilling() {
  return (
    <div className='Drilling'>
        <DrillingBanner/>
        <DrillingDetails/>
        <Footer/>

    </div>
  )
}

export default Drilling