import React from 'react'
import '../../styles/MaterialPromo.css'
import { Link, useLocation } from 'react-router-dom';


function MaterialPromo() {
    return (
        <div className='MaterialPromo'>
            <div className="container">
                <div className="row">
                    <div className="left-col">
                        <div className="col-container">
                            <div className="text">
                                <h2>Materials</h2>
                            </div>
                            <Link to="/materials">
                                <div class="arrow-container animated fadeInDown">
                                    <div class="arrow-2">
                                        <i class="fa fa-angle-right"></i>
                                    </div>
                                    <div class="arrow-1 animated hinge infinite zoomIn"></div>
                                </div>
                            </Link>
                        </div>
                        <p>Explore more about mineral plants here!</p>

                    </div>

                    <div className="right-col">
                        <div className="image-container "data-aos="fade-left"data-aos-duration="1000">
                            <div className="explore">
                                <h3>Chrome Concentrate</h3>
                            </div>
                            <img src="img/chrome_concentrate.jpg" alt="chrome concentrate" />
                        </div>
                        <div className="image-container "data-aos="fade-left"data-aos-duration="1000"data-aos-delay="400">
                            <div className="explore">
                                <h3>Marble <br />& Plants</h3>
                            </div>
                            <img src="img/marble-quarry.jpg" alt="marble" />
                        </div>
                        <div className="image-container "data-aos="fade-left"data-aos-duration="1000"data-aos-delay="800">
                            <div className="explore">
                                <h3>Laterite</h3>
                            </div>
                            <img src="img/laterite.jpeg" alt="laterite" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaterialPromo