import React from 'react'
import '../../styles/Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div className='Footer'>
            <div className="container">
                <div className="row">
                    <div className="left-col">
                        <div className="logo">
                            <img src="img\gulfmining_logo.png" alt="gulf mining logo" />
                        </div>
                        <div className="content">
                            <h3>GULF MINING GROUP</h3>
                            {/* <p>P.O.Box 1828,P.C. 130, Azaiba, Sultanate of Oman</p> */}
                            <p>Phone: <a href="tel:+968 24210700">+968 24210700</a>, </p>
                            <p>Fax: <a href="tel:+968 24210771">+968 24210771</a></p>
                            <p>For business enquiries: <a href="mailto:info@gulfmining.com">info@gulfmining.com</a></p>

                        </div>
                    </div>
                    <div className="mid-col">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/career">Career</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="right-col">
                        <ul>
                            <li><Link to="/materials">Materials</Link></li>
                            <li><Link to="/branch">Our Branch</Link></li>
                            <li><Link to="/drilling">Drilling & Blasting</Link></li>
                        </ul>
                        <div class="social-media">
                            <a href="#" target="_blank"
                                rel="noopener noreferrer"><span class="social-media"><i
                                    class="fa-brands fa-facebook"></i></span></a>
                            <a href="#" target="_blank"
                                rel="noopener noreferrer"><span class="social-media"><i
                                    class="fa-brands fa-instagram"></i></span></a>

                            <a href="mailto:admin@gulfmining.com" target="_blank" rel="noopener noreferrer"><span
                                class="social-media"><i class="fa-solid fa-envelope"></i></span></a>

                        </div>
                    </div>
                </div>


            </div>
            <div className="copyright">
                <p>© Gulf Mining Group</p>
            </div>
        </div>
    )
}

export default Footer