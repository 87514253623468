import React from 'react'
import '../../styles/HeroSection.css'
import Navbar from '../Navbar/Navbar'

function HeroSection() {
  return (
    <div className='HeroSection'>
      <Navbar />
      <div className="HeroSection-bg"></div>
      <div className="container">
        <div className="row">
          <div className="left-col content"data-aos="fade" data-aos-duration="1000">
            <h1><span>Gulf Mining Group: </span>
              A Journey of Innovation in Mineral Development</h1>
            <p>Gulf Mining Group's journey of innovation knows no bounds. We're not just shaping Oman's mineral industry; we're propelling it into the future. Through advanced technologies, responsible mining, and unwavering dedication, we're paving the way for a mineral-rich and sustainable Oman.</p>
            <a href="/about">Read more</a>
          </div>

        </div>

      </div>
      <div className="black-overlay">

      </div>
    </div>
  )
}

export default HeroSection