import React from 'react'
import '../../styles/AboutDetails.css'

function AboutDetails() {
    return (
        <div className='AboutDetails'>
            <div className="container">
                <div className="AboutUs">
                    <h2 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">PROFILE</h2>
                    <h3 data-aos="fade" data-aos-duration="1000"><i class="fa-solid fa-quote-left"></i>The group
                        has grown rapidly
                        become one
                        of the largest
                        mining
                        companies
                        in Oman<i class="fa-solid fa-quote-right"></i></h3>
                    <p data-aos="fade" data-aos-duration="1000">Gulf Mining Materials Company was established in 2005, the flagship of
                        the group, with an interest in the development and exploration of
                        minerals in Sultanate of Oman and abroad. The company is owned by
                        <b> Mr. Abdullah bin Ahmed bin Sulaiman Al Hadi</b>, Founder and Chairman
                        of the group who worked on the composition of the company and find
                        a means of growth and development for the advancement of the company's future according to the ambition visions beyond the aspirations
                        of many of his predecessors in this field and make for that a lot of time
                        and effort.<br/><br/>
                        Gulf Mining Group (GMG) has a recognized history of mining and mineral development in Oman and abroad. The Chairman recognized the
                        potential of Oman’s abundant mineral resources and in particular
                        Chrome Ore over ten years ago. The Group has since diversified significantly with interests in Chrome, Manganese, Marble, limestone, iron
                        ore, laterite, Gypsum, Potash, Shipping and Minerals trading.<br/><br/>
                        GMG has grown rapidly to be one of the largest mining companies
                        in Oman. Also, it leading producer of marble and owns and
                        operates one of the largest Ferrochrome smelting in
                        Oman, with two furnaces.<br/><br/>
                        The Sultanate of Oman has a wide variety of
                        mineral resources. GMG has focused its
                        capabilities in developing the vast mineral
                        resources the country has to offer and
                        it working seriously to emerge as
                        the leading player in Oman’s
                        mineral based businesses. </p>


                    <h2 className='vision box-reveal' data-aos="custom-box-animation" data-aos-offset="100">VISION</h2>
                    <p data-aos="fade" data-aos-duration="1000">To position Gulf Mining Group as a major player
                        in the region in the field of mining and exporting
                        of minerals.</p>
                    <h2 className='mission box-reveal' data-aos="custom-box-animation" data-aos-offset="100">MISSION</h2>
                    <p data-aos="fade" data-aos-duration="1000">We are leading Group that explores, discovers and transforms minerals into fortune resources to develop community. Working to improve the Group’s scope of work by following all the
                        performance strategies and by contributing in making the mineral industry one of the most
                        important sources of national income to the Sultanate of Oman.</p>
                    <h2 className='values box-reveal' data-aos="custom-box-animation" data-aos-offset="100">VALUES</h2>
                    <ol data-aos="fade" data-aos-duration="1000">
                        <li>Customer needs are met & exceeded</li>
                        <li>Safety and welfare for everyone</li>
                        <li>Sustainable operations only</li>
                        <li>People development is an imperative</li>
                        <li>Community investment means everyone benefits</li>
                        <li>Fairness and integrity is a our key behaviors</li>
                    </ol>

                </div>
            </div>
        </div>
    )
}

export default AboutDetails