import React from 'react'
import Navbar from '../Navbar/Navbar'
import '../../styles/AboutBanner.css'

function AboutBanner() {
    return (
        <div className='AboutBanner'>
            <Navbar />
            <div className="container">
                <img src="img/about_banner.jpg" alt="gulf mining about banner" />
            </div>
            <div className="black-gradient">

            </div>
            <div className="text">
                <h2>About</h2>
            </div>
        </div>
    )
}

export default AboutBanner