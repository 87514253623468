import React from 'react'
import '../../styles/ContactDetails.css'

function ContactDetails() {
    return (
        <div className='ContactDetails'>
            <div className="container">
                <section className='networking'>
                    <div className="image-container">
                        <img src="img/networking.png" alt="networking" />
                    </div>
                    <h2>NETWORKING</h2>
                    <ul>
                        <li className='Samail'>Samail Chrome Mines </li>
                        <li className='Ibra'>Ibra / Manganese Mine / Chrome Mine</li>
                        <li className='Salalah'>Salalah / Gypsum Mine</li>
                        <li className='Sohar'>Sohar / Chrome Mine</li>
                        <li className='Marble'>Ibri / Marble Mine</li>
                        <li className='Buraimi'>Buraimi / Marble Plant</li>
                        <li className='Potassium'>Potassium / Umm As Samim</li>
                    </ul>
                </section>
                <section className='global-network'>
                    <div className="image-container">
                        <img src="img/global-networking.png" alt="global networking" />
                    </div>
                    <h2>GLOBAL NETWORK OFFICES:</h2>
                    <div className="offices">
                        <div className="muscat">
                            <h3>GULF MINING MATERIALS MUSCAT</h3>
                            {/* <p>P.O.Box 1828,P.C. 130, Azaiba, Sultanate of Oman </p> */}
                            <p>Phone: <a href="tel:+968 24210700">+968 24210700</a>, Fax: <a href="tel:+968 24210771">+968 24210771</a></p>
                            <p>Website :www.Gulfmining.com</p>
                            <p>For Business enquiries : <a href="mailto:info@Gulfmining.com">info@Gulfmining.com</a></p>
                        </div>
                        <div className="china">
                            <h3>GULF MINING MATERIALS CHINA</h3>
                            <p>28F, Building 2, China Phoenix Building, 2008 Shennan Road, Futian, Shenzhen, China</p>
                            <p>Office number: <a href="tel:+86-755-86672289">+86-755-86672289</a></p>
                        </div>
                        <div className="lugano">
                            <h3>GULF MINING MATERIALS LUGANO SA</h3>
                            <p>Via Nassa 7, 6900 Lugano, Switzerland</p>
                            <p>For Business enquiries <a href="mailto:sales@gmmlugano.com">sales@gmmlugano.com</a></p>
                        </div>
                        <div className="tirana">
                            <h3>GULF MINING MATERIALS ALBANIA TIRANA</h3>
                            <p>1000, “Vasil Shanto” road No.5 ,Building no.4 , Floor 1st , Apartment no.10 ,
                                Albania </p>
                        </div>
                        <div className="dubai">
                            <h3>GULF MINING MATERIAL DUBAI</h3>
                            <p>The Citadel Tower,</p>
                            <p>Office No 1109,1110, Business Bay </p>
                            <p>P.O.Box 30321,</p>
                            <p>Dubai , U.A.E.</p>
                            <p>Office Tel No : <a href="tel:00971 4 3649386">00971 4 3649386</a></p>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default ContactDetails