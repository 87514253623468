import React from 'react'
import MaterialBanner from '../Components/MaterialBanner/MaterialBanner'
import MaterialDetails from '../Components/MaterialDetails/MaterialDetails'
import Footer from '../Components/Footer/Footer'

function Materials() {
  return (
    <div className='Materials'>
        <MaterialBanner/>
        <MaterialDetails/>
        <Footer/>

        
    </div>
  )
}

export default Materials