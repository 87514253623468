import React from 'react'
import Navbar from '../Navbar/Navbar'
import '../../styles/CareerBanner.css'

function CareerBanner() {
    return (
        <div className='CareerBanner'>
            <Navbar />
            <div className="container">
                <img src="img/career-banner.png" alt="gulf mining career banner" />
            </div>
            <div className="black-gradient">

            </div>
            <div className="text">
                <h2>Career</h2>
            </div>
        </div>
    )
}

export default CareerBanner