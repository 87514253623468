import React from 'react'
import '../../styles/DrillingDetails.css'

function DrillingDetails() {
    return (
        <div className='DrillingDetails'>
            <div className="container">
                <h2 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">DRILLING AND BLASTING</h2>
                <p>With numerous qualified and technically competent personnel GMG handles drilling, blasting
                    and consulting services.</p>
                <div className="drilling-services">
                    <h3>DRILLING SERVICES</h3>
                    <ul>
                        <li>Core Drilling</li>
                        <li>Non Core Drilling</li>
                    </ul>
                </div>
                <div className="blasting-services">
                    <h3>BLASTING SERVICES</h3>
                    <ul>
                        <li>Quarry Blasting</li>
                        <li>Road Works </li>
                        <li>Tunneling</li>
                        <li>Special Controlled Blasting</li>
                    </ul>
                </div>
                <div className="image-container black-box-reveal"data-aos="custom-black-box-reveal"data-aos-duration="1000">
                    <img src="img/blasting.jpg" alt="blasting" />
                </div>

            </div>
        </div>
    )
}

export default DrillingDetails