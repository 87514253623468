import React from 'react'
import CareerBanner from '../Components/CareerBanner/CareerBanner'
import CareerDetails from '../Components/CareerDetails/CareerDetails'
import Footer from '../Components/Footer/Footer'

function Career() {
  return (
    <div className='Career'>
        <CareerBanner/>
        <CareerDetails/>
        <Footer/>
    </div>
  )
}

export default Career