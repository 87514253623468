import React from 'react'
import BranchBanner from '../Components/BranchBanner/BranchBanner'
import BranchDetails from '../Components/BranchDetails/BranchDetails'
import Footer from '../Components/Footer/Footer'

function Branch() {
  return (
    <div className='Branch'>
        <BranchBanner/>
        <BranchDetails/>
        <Footer/>

    </div>
  )
}

export default Branch