import React from 'react'
import '../../styles/OurTeam.css'
function OurTeam() {
    return (
        <div className='OurTeam'>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">OUR TEAM</h2>
                <div className="chairman">
                    <div className="image-container " data-aos="fade" data-aos-duration="1000">
                        <img src="img/chairman-img.jpg" alt="chairman of gulf mining group" />
                    </div>
                    <h3 className='values box-reveal' data-aos="custom-box-animation" data-aos-offset="100">CHAIRMAN OF THE GROUP</h3>
                    <p data-aos="fade" data-aos-duration="1000">35 years work experiences. His Excellency Abdulla Ahmad Sulaiman Al Hadi, the founder
                        and chairman of Gulf Mining Group is a visionary who saw the potential of GMG playing a
                        significant role in developing abundant mineral resources of Sultanate of Oman particularly
                        the chromite.</p>
                </div>
                <div className="other-member">
                    <div className="row">
                        <div className="left-col" data-aos="fade" data-aos-duration="1000">
                            <div className="image-container " data-aos="fade" data-aos-duration="1000">
                                <img src="img/vice-chairman.jpg" alt="vice chairman of gulf mining group" />
                            </div>
                            <h3 className='values box-reveal' data-aos="custom-box-animation" data-aos-offset="100">VICE CHAIRMAN</h3>


                        </div>
                        {/* <div className="right-col">
                            <div className="image-container " data-aos="fade" data-aos-duration="1000">
                                <img src="img/chairman-img.jpg" alt="chairman of gulf mining group" />
                            </div>
                            <h3 className='values box-reveal' data-aos="custom-box-animation" data-aos-offset="100">STAFF PHOTO</h3>

                        </div> */}
                    </div>
                </div>
                <div className="social-responsibility">
                    <h2 className='social-resp-h2' data-aos="fade" data-aos-duration="1000">SOCIAL RESPONSIBILITIES </h2>
                    <p data-aos="fade" data-aos-duration="1000">As a proud Omani Group with multi-cultural staff and business associates, Gulf Mining takes
                        its Corporate Social Responsibilities very seriously. The Group believes in working for the up
                        liftmen and prosperity of the communities it works with. Besides direct and indirect employment opportunities, the Group is creating social, education, sports and healthcare facilities
                        for the local population and participating in several community development schemes. In all
                        the places where it works, it takes great care to ensure harmony with the local population
                        so as to respect and preserve their life styles.</p>
                </div>
                <div className="iso">
                    <h2 className='iso_h2'>ISO</h2>
                    <h3 className='iso_h3 box-reveal' data-aos="custom-box-animation" data-aos-offset="100">THE GROUP INTEGRATED
                        MANAGEMENT SYSTEM (ISO)</h3>
                    <div className="image-container black-box-reveal"data-aos="custom-black-box-reveal"data-aos-duration="1000">
                        <img src="img/iso.webp" alt="iso certification" />
                    </div>

                    <p data-aos="fade" data-aos-duration="1000">ISO 14001:2004 specifies requirements for an environmental management system to enable
                        an organization to develop and implement a policy and objectives which take into account
                        legal requirements and other requirements to which the organization subscribes, and information about significant environmental aspects. It applies to those environmental aspects
                        that the organization identifies as those which it can control and those which it can influence.
                        It does not itself state specific environmental performance criteria.<br/><br/>
                        ISO 9001:2008 is a quality management system standard, first published in 1987 by ISO (International Organization for Standardization). This standard is designed to help organizations
                        ensure that they meet all requirements of customers and stakeholders.<br/><br/>
                        It is neither an industry nor product specific standard. Organizations of all sizes and types can
                        be certified against ISO 9001. This standard can be used by manufacturing or service providing companies. This standard assures quality for all interested parties involved in businesses
                        that are certified.<br/><br/>
                        OHSAS 18001, Occupational Health and Safety Assessment Series, (officially BS OHSAS
                        18001) was a British Standard for occupational health and safety management systems. Compliance with it enabled organizations to demonstrate that they had a system in place for
                        occupational health and safety.
                        SOCIAL RESPO</p>
                </div>


            </div>
        </div>
    )
}

export default OurTeam