import React from 'react'
import'../../styles/BranchDetails.css'

function BranchDetails() {
    return (
        <div className='BranchDetails'>
            <div className="container">
                <section className='ferro_alloy'>
                    <h2 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">GULF MINING FERRO ALLOYS(FZC)</h2>
                    <p data-aos="fade" data-aos-duration="1000">The Ferrochrome plant was established in January 2015. The ferrochrome plant complements
                        sequentially to processing the milled chrome ore produce from the mines and COB plant in
                        Samail.
                        The smelter focuses on the conversion of the raw chrome to high quality metal alloy pellets
                        that have an elevated market value. Ferrochrome (Fe Cr) is an alloy of chromium and iron
                        containing between 50% and 70% chromium. The ferrochrome is produced by
                        electric arc melting of chromite, an iron
                        magnesium chromium oxide and the
                        most important chromium ore.
                        The Plant covers a total area of 60,000
                        sq meters with additional space available
                        for future expansions of about 40,000
                        sq meters. It has the capacity of 50,000
                        metric tons per year of pellets of ferrochrome which are exported to Eastern
                        Asia markets, Western Europe and
                        other countries.
                    </p>
                </section>
                <section className='victory'>
                    <h2 className='victory box-reveal' data-aos="custom-box-animation" data-aos-offset="200">VICTORY INTERNATIONAL
                        INVESTMENT CO. LLC</h2>
                    <p data-aos="fade" data-aos-duration="1000">
                        Was established in with interest in the development and exploration of minerals including
                        limestone , the company specialized in processing and exporting limestone.
                        Limestone chemically known as CaCo3, which is white in color, and sometimes accompanied
                        by impurities such as clay, sand and iron oxides making it appear in different colors. It consists
                        of sediments and aquatic organisms. As is the case for its geological nature that contains
                        varying amounts of silica and varying amounts of pure limestone. In the company site there
                        are two types of limestone ore. The first high quality with a purity of up to 99.5% , while the
                        second type less purity up to 94%. The process of producing and extracting the limestone
                        material is done by crushing to different sizes according to the use of the factories. For this
                        purpose, a modern crusher with a capacity of 450 metric tons per hour was installed.
                    </p>
                </section>
                <section className='alruwaiya'>
                    <h2 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">AL RUWAIYA MINING LLC</h2>
                    <p data-aos="fade" data-aos-duration="1000">The demand for gypsum has been grows , mainly from cement and wallboard manufacturers.
                        Gypsum from Oman exports expected to increase nearly double the present level, which will
                        make it the fourth largest gypsum producing country. Currently 100,000Mt/Month of Gypsum
                        ore is produced and exported from Salalah mines. GMG is considering building a gypsum wallboard plant in Salalah. The project is a part of future portfolio planned investments sc</p>
                </section>
                <section className='mina'>
                    <h2 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">MINA ENGINEERING LLC</h2>
                    <p data-aos="fade" data-aos-duration="1000">Was established in 2013. it has several activities like repair, maintenance of electrical , motors,
                        generators and transformers. Also its operated sand and pebble including manganese mine.
                        The group owns a high capacity, shallow-deposit Manganese mine: production is accelerating, with grades increasing following construction of a beneficiation plant. Gulf Mining Group’s
                        manganese deposit is very accessible; the ore body is exposed in many places: recovery is
                        straightforward and grades are high. GMG has started the development and build of a beneficiation plant, which will increase the grade from 20 grade to 34-36 grade</p>
                </section>
                <section className='potassium_mining'>
                    <h2 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">GULF OF POTASSIUM MINING LLC</h2>
                    <p data-aos="fade" data-aos-duration="1000">
                        Gulf of Potassium Mining LLC established in 2018 (“Gulf Potassium”) intends to mine a
                        substantial near surface potash deposit in the Omani Empty Quarter. The deposit is located
                        between the border with Saudi Arabia and BP’s Block 61. The project is a Brine extraction
                        project which involves extraction of mineral rich Brines from just below the surface (max 10m
                        depth) and then processing the Brines to extract the Minerals. Primarily the focus is on
                        Potash however the brine almost certainly has other economically viable minerals which will
                        be tested for and extracted once proven.
                        The Potash will be processed in Oman to produce high-quality fertilizer products, primarily
                        Sulphate of Potash (SOP) which will be sold into the local market to replace imported fertilizers or be exported dependent on demand. Use of Omani Gas and Sulphur will increase added
                        in country value. A project life of 20 years has been assumed however it is likely to be extended, possibly up to 40 years, dependent on resource confirmation.
                        Potassium Sulphate, or SOP, is considered a premium-quality potash Fertilizer and has two
                        nutrients, Potassium and Sulphur. SOP is most commonly used on high-value crops like fruits,
                        vegetables, nuts, tea and coffee. Estimated reserve in excess of 40 Million tons KCl. Production capacity estimated by 500ktpa and potential ramp up to 1mtpa. Capital Investment
                        expected to be between $300m to $500m and expected to create 500 direct employment
                        and a further 1000+ indirect.
                    </p>
                </section>
                <section className='beach_shipping'>
                    <h2 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">GULF BEACH SHIPPING AGENCY</h2>
                    <p data-aos="fade" data-aos-duration="1000">A group outfit, commenced business in late 2007 and it handles the entire in-house logistical
                        requirements of the group, besides catering to the needs of other importers and exporters
                        in the Sultanate. GBSA is equipped to provide reliable, high quality logistics/ cargo services,
                        customized to meet client requirements</p>
                    <h3 data-aos="fade" data-aos-duration="1000">SHIPPING SERVICES:</h3>
                    <ul data-aos="fade" data-aos-duration="1000">
                        <h4>GBSA's services include:</h4>
                        <li>Advisory on logistical solutions</li>
                        <li>Cargo Clearance Service</li>
                        <li>Freight Forwarding Service</li>
                        <li>International Shipping</li>
                        <li>Inland Transportation </li>
                        <li>Custom Clearance</li>
                    </ul>
                </section>

            </div>
        </div>
    )
}

export default BranchDetails