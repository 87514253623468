import React, { useContext, useState } from 'react';
import '../../styles/Navbar.css';
import MenuContext from '../../Context/menuProvider';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const { menuOpen, handleToggleMenu, navbar, handleNavbar, setImage, overlay, image, setOverlay } = useContext(MenuContext);
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation(); // Get the current location

  const handleMenuItemClick = () => {
    if (window.innerWidth < 576) {
      handleToggleMenu();
    }
  };

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const shouldReload = (path) => {
    // Check if the clicked link matches the current location
    return location.pathname === path;
  };

  return (
    <div>
      <div className='Navbar'>
        <header className={navbar ? 'header active' : 'header'}>
          <div className="logo">
            <Link to="/"><img src={image} alt="gulf mining logo" /></Link>
          </div>
          <nav className='nav-menu'>
            <ul className={menuOpen ? 'nav-links active' : 'nav-links'}>
              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/') && window.location.reload(); }} to="/">Home</Link>
              </li>
              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/about') && window.location.reload(); }} to="/about">About</Link>
              </li>
              <li>
                <a href="#!" onClick={handleDropdown}>Operations&nbsp;<i className="fa-solid fa-chevron-down"></i></a>
                <div className={dropdown ? 'dropdown active' : 'dropdown'}>
                  <li><Link onClick={() => { handleMenuItemClick(); shouldReload('/materials') && window.location.reload(); }} to="/materials">Materials</Link></li>
                  <li><Link onClick={() => { handleMenuItemClick(); shouldReload('/branch') && window.location.reload(); }} to="/branch">Our Branch</Link></li>
                  <li><Link onClick={() => { handleMenuItemClick(); shouldReload('/drilling') && window.location.reload(); }} to="/drilling">Drilling & Blasting</Link></li>
                </div>
              </li>
              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/career') && window.location.reload(); }} to="/career">Career</Link>
              </li>
              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/contact') && window.location.reload(); }} to="/contact">Contact</Link>
              </li>
            </ul>
            <div className="menu-button">
              <label htmlFor="check">
                <input type="checkbox" id="check" onClick={handleMenuItemClick} />
                <span></span>
                <span></span>
                <span></span>
              </label>
            </div>
            <div className={overlay ? 'overlay active' : 'overlay'}></div>
          </nav>
        </header>
      </div>
    </div>
  );
}

export default Navbar;
