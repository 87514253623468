import React from 'react'
import Navbar from '../Navbar/Navbar'
import '../../styles/BranchBanner.css'

function BranchBanner() {
    return (
        <div className='BranchBanner'>
            <Navbar />
            <div className="container">
                <img src="img/mining_branch.jpeg" alt="gulf mining mining branch" />
            </div>
            <div className="black-gradient">

            </div>
            <div className="text">
                <h2>Our Branch</h2>
            </div>
        </div>
    )
}

export default BranchBanner