import React from 'react'
import '../../styles/DrillingBanner.css'
import Navbar from '../Navbar/Navbar'


function DrillingBanner() {
    return (
        <div className='DrillingBanner'>
            <Navbar />
            <div className="container">
                <img src="img/drilling_blasting.jpeg" alt="gulf mining drilling banner" />
            </div>
            <div className="black-gradient">

            </div>
            <div className="text">
                <h2>Drilling & Blasting</h2>
            </div>
        </div>
    )
}

export default DrillingBanner