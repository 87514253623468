import React from 'react'
import Navbar from '../Navbar/Navbar'
import '../../styles/ContactBanner.css'

function ContactBanner() {
    return (
        <div className='ContactBanner'>
            <div className="container">
                <Navbar />
                <div className="container">
                    <img src="img/mining_contact.jpeg" alt="gulf mining contact banner" />
                </div>
                <div className="black-gradient">

                </div>
                <div className="text">
                    <h2>Contact</h2>
                </div>
            </div>
        </div>
    )
}

export default ContactBanner