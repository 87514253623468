import React from 'react'
import '../../styles/CareerDetails.css'

function CareerDetails() {
    return (
        <div className='CareerDetails'>
            <div className="container">
                <div className="career">
                    <h2 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">CAREERS</h2>
                    <p data-aos="fade" data-aos-duration="1000">
                        At Gulf Mining Group, we have unique way of working, with guiding principles that help us to
                        achieve our targets. These are the ways we choose to work, the ways we choose to achieve
                        success. We aim to attract, develop and retain the best people. We are committed to creating
                        new jobs for Omanis, whilst offering opportunities for all expatriate employees to develop
                        and fulfill careers within the Group in which they are proud to work. We have equal opportunities and believe in the potential of all our staff.<br /><br />
                        We look for reliable individuals with a wealth of experience within the minerals and mining
                        industry as well as those with a hard work ethics. We have created efficient operational structures, ensuring the right people are in the right place. We provide the necessary support and
                        training for all staff to grow and succeed.

                    </p>

                </div>
                <div className="invitation">
                    <p>We invite you to reach out to us and share your resume at <a href="mailto:admin@gulfmining.com">admin@gulfmining.com</a>.We welcome your inquiries and applications. <a href="/Contact">Contact Us</a></p>
                </div>


            </div>

        </div>
    )
}

export default CareerDetails