import React from 'react'
import Navbar from '../Navbar/Navbar'
import '../../styles/MaterialBanner.css'

function MaterialBanner() {
  return (
    <div className='MaterialBanner'>
        <Navbar />
            <div className="container">
                <img src="img/materials_banner.jpeg" alt="gulf mining materials banner" />
            </div>
            <div className="black-gradient">

            </div>
            <div className="text">
                <h2>Materials</h2>
            </div>
    </div>
  )
}

export default MaterialBanner