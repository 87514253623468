import React from 'react'
import AboutBanner from '../Components/AboutBanner/AboutBanner'
import AboutDetails from '../Components/AboutDetails/AboutDetails'
import OurTeam from '../Components/OurTeam/OurTeam'
import Footer from '../Components/Footer/Footer'

function About() {
  return (
    <div className='About'>
        <AboutBanner/>
        <AboutDetails/>
        <OurTeam/>
        <Footer/>
    </div>
  )
}

export default About