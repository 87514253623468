import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Materials from './Pages/Materials';
import About from './Pages/About';
import Branch from './Pages/Branch';
import Career from './Pages/Career';
import Contact from './Pages/Contact';
import Drilling from './Pages/Drilling';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/materials' element={<Materials />} />
          <Route path='/about' element={<About />} />
          <Route path='/branch' element={<Branch />} />
          <Route path='/career' element={<Career />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/drilling' element={<Drilling />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
