import React from 'react'
import HeroSection from '../Components/Herosection/HeroSection'
import AboutIntro from '../Components/AboutIntro/AboutIntro'
import Footer from '../Components/Footer/Footer'
import MaterialPromo from '../Components/MaterialPromo/MaterialPromo'

function Home() {
  return (
    <div className='Home'>
        <HeroSection/>
        <AboutIntro/>
        <MaterialPromo/>
        <Footer/>
    </div>
  )
}

export default Home