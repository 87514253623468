import React from 'react'
import '../../styles/AboutIntro.css'
import { Link } from 'react-router-dom';


function AboutIntro() {
    return (
        <div className='AboutIntro'>
            <div className="container">
                <div className="row">
                    <div className="left-col">
                        <h2 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">About US</h2>
                        <p data-aos="fade"data-aos-duration="900">Founded in 2005, Gulf Mining Group (GMG) is a leading player in Oman's mineral industries. With a rich history of mining and mineral development, GMG's diverse interests span Chrome, Manganese, Marble, Limestone, and more.

                            Driven by a commitment to harness Oman's mineral potential, GMG has grown rapidly to become one of Oman's largest mining companies. Discover our story and explore our journey on the full "About Us" page.</p>
                        <a href="/about">Read More</a>
                    </div>
                    <div className="right-col">
                        <div className="image-container black-box-reveal"data-aos="custom-black-box-reveal"data-aos-duration="1000" data-aos-offset="400">
                            <img src="img/about_intro.jpg" alt="about intro" />

                        </div>
                    </div>
                </div>
                <hr />
                <div className="bottom-section">
                    <div className="card"data-aos="fade" data-aos-duration="1000">
                        <div className="icon">
                            <img className='black-box-reveal' data-aos="black-box-animation" src="img/material.png" alt="material icon" />
                        </div>
                        <h3>Materials</h3>
                        <Link to="/materials">Read More</Link>

                    </div>
                    <div className="card"data-aos="fade" data-aos-duration="1000">
                        <div className="icon">
                            <img src="img/branch.png" alt="branch icon" />
                        </div>
                        <h3>Branch</h3>
                        <Link to="/branch">Read More</Link>

                    </div>
                    
                    <div className="drilling card"data-aos="fade" data-aos-duration="1000">
                        <div className="drilling icon">
                            <img src="img/drilling.png" alt="drilling icon" />
                        </div>
                        <h3 className='drilling'>Drilling & Blasting</h3>
                        <Link to="/drilling">Read More</Link>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutIntro