import React from 'react'
import '../../styles/MaterialDetails.css'

function MaterialDetails() {
    return (
        <div className='MaterialDetails'>
            <div className="container">
                <section className='Introduction'>
                    <p data-aos="fade" data-aos-duration="1000">Mining Industry in the Sultanate of Oman with particular reference to chrome ore had been
                        quite passive until Gulf Mining Group stepped into this field in 2005.<br/><br/>
                        Gulf Mining Group is presently among the largest mining companies in Oman and quite easily
                        the market leader in production and export of chrome ore from Oman.<br/><br/>
                        Gulf Mining Group has played a major role in establishing Omani chrome ore in the world
                        market. Starting off on a modest scale in 2005, it has now emerged as one of the largest
                        producer and exporter of Chrome Ore. The group offers chrome ore in lumpy and fines form
                        with chrome content ranging from 24% to 38%.</p>
                </section>
                <section className="ChromeConcentrate"id='ChromeConcentrate'>
                    <div className="row">
                        <div className="left-col">
                            <h3 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">CHROME CONCENTRATE </h3>
                            <p data-aos="fade" data-aos-duration="1000">With a view to add value and to meet customer expectations, the Group has set up the
                                first-ever chrome upgradation plant in Oman with a monthly ore processing capacity of
                                15,000 MT. The plant offers chrome ore concentrates of purity 38% and above. Having
                                advantage over South African chrome concentrate in terms of Cr : Fe ratio, the product has
                                been received very well by the end users.</p>

                        </div>
                        <div className="right-col">
                            <div className="image-container black-box-reveal"data-aos="custom-black-box-reveal"data-aos-duration="1000" data-aos-offset="300">
                                <img src="img/chrome_concentrate.jpg" alt="chrome concentrate" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='Marble'>
                    <div className="row">
                        <div className="left-col">
                            <div className="image-container black-box-reveal"data-aos="custom-black-box-reveal"data-aos-duration="1000" data-aos-offset="300">
                                <img src="img/marble-quarry.jpg" alt="marble" />
                            </div>
                        </div>
                        <div className="right-col">
                            <h3 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">MARBLE PLANT AND MINES</h3>
                            <p data-aos="fade" data-aos-duration="1000">Started in the year 2008 keeping in view the company’s expansion plans and anticipating
                                demand & growth in the construction industry.<br/><br/>
                                The marble processing plant is equipped with the latest equipment from Italy. The Group
                                produces unpolished and polished slabs as also polished tiles in different popular sizes.
                                Currently, it operates three marble stone quarries and has a few more under development.
                                Omani marble has created a niche market for itself in all parts of the globe and it is known for
                                its aesthetics and durability. Omani marble products go to China, India, USA, Europe, GCC
                                and other South East Asian countries, to name a few.</p>

                        </div>

                    </div>
                </section>
                <section className='Laterite'>
                    <div className="row">
                        <div className="left-col">
                            <h3 className='box-reveal' data-aos="custom-box-animation" data-aos-offset="200">LATERITE</h3>
                            <p data-aos="fade" data-aos-duration="1000">
                                GMG will start development of a large scale Laterite deposit; initial geology confirms a
                                substantial iron ore deposit, with presence of Nickel and Cobalt being verified This a large
                                scale greenfield site which will eventually support a substantial mining and refining operation
                                with associated support infrastructure Low grade Iron Ore will initially be mined (~40%) with
                                beneficiation driving production to ~60%.
                            </p>
                        </div>
                        <div className="right-col">
                            <div className="image-container black-box-reveal"data-aos="custom-black-box-reveal"data-aos-duration="1000" data-aos-offset="300">
                                <img src="img/laterite.jpeg" alt="laterite" />
                            </div>

                        </div>
                    </div>

                </section>
            </div>
        </div>
    )
}

export default MaterialDetails